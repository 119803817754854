import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/theme/saas';
import { agencyTheme } from 'common/theme/agency';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import { ResetCSS } from 'common/assets/css/style';
import { GlobalStyle, AgencyWrapper } from 'containers/Agency/agency.style';
import Navbar from 'containers/Agency/Navbar';
import Banner from 'containers/Crypto/BannerSection';
import TimelineSection from 'containers/Saas/TimelineSection';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import Seo from 'components/seo';

const Saas = ({
  row,
  col,
  textStyle,
  title
}) => {
  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <Seo title="Sales Phases" />
        <ResetCSS />
        <GlobalStyle />
        <AgencyWrapper>
          <Sticky top={0} innerZ={9999} innerClass="sticky-nav-active" activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar  menu2={true} />
            </DrawerProvider>
          </Sticky>
          <Banner />
          {/* <FeatureSection />
          
          <VisitorSection />
          <ServiceSection />
          <PricingSection />
          <TestimonialSection />
          <PartnerSection /> */}
          <TimelineSection />
          <Box {...row}>
            <Text {...title}>1. Rapport & Credibility Build</Text>
            <Text {...textStyle}>Most sales reps are amicable enough types, with the skill to easily form a somewhat superficial new
            business relationship. However, the ability to establish a real & trusted connection with key contacts at
            the client is a higher-level skill and much less common. This skill separates the top 5 percent of
            achievers from the rest of the selling world.</Text>
            <Text {...textStyle}>So what does it mean to form a real & trusted connection? It all comes down to how quickly your rep
            moves from the kind of schmoozy, superficial connection to a deeper relationship with the client. They
            do this by making a <b>personal</b> and <b>professional</b> connection – both are important.</Text>
            <Text {...textStyle}>By doing this, your rep will gain superior insight into all aspects about the client’s process (key
            personalities & perspectives involved, major pain points, risks, opportunities, etc.). Your rep is spending
            more time with the contacts at the prospect, and that time is providing richer and better information
            than the competition.</Text>
            <Text {...textStyle}><b>Moving to the Trusted Advisor role with the client.</b> The personal connection is easy for most reps. It’s
            all about finding areas of common interest (family, interests, hobbies, etc.). The personal connection is
            really “table stakes” in the process. If your rep is having problems in this area, they should likely
            consider a different career choice – it’s a success killer.</Text>
            <Text {...textStyle}>However, many reps spend too much time on the personal (real) connection and not nearly enough on
            the professional (trusted) connection, which is also highly detrimental to success. The professional
            connection involves getting to know the client’s business, their overall business objectives and the
            people involved and their perspectives – it’s essentially getting deep insight into the dynamics of the
            evaluation and decision process. Top sales reps show their industry knowledge by asking the right
            questions, thereby teasing out critical information that will be used throughout the sales process. The
            prospect has a strong feeling that your rep “really knows their stuff” which allows them to transition to a
            trusted advisor role. Once a rep has achieved trusted advisor status, they have gained a key advantage
            over the rest of the competition, giving your solution the inside track and an easier path to success.</Text>
            <table>
              <tr>
                <td>
                  Skill 1
                </td>
                <td>
                  Rep is having long and productive phone or in person meetings with the client
                </td>
              </tr>
              <tr>
                <td>
                  Skill 2
                </td>
                <td>
                Rep has gained “trusted advisor” status with prospect
                </td>
              </tr>
              <tr>
                <td>
                  Skill 3
                </td>
                <td>
                Rep has established inside “coach” at the prospect. Gets richer info than competitors
                </td>
              </tr>
              <tr>
                <td>
                  Skill 4
                </td>
                <td>
                Rep can easily get key contacts on the phone or to attend face to face meetings
                </td>
              </tr>
              <tr>
                <td>
                  Skill 5
                </td>
                <td>
                Rep can easily get key contacts on the phone or to attend face to face meetings
                </td>
              </tr>
            </table>
          </Box>
          <Box {...row} style={{marginTop:30}}>
            <Text {...title}>2. Discovery</Text>
            <Text {...textStyle}>During the discovery phase, your rep gathers all the information necessary to conduct successful
              product demonstrations and to build a compelling business case for the decision team. Many reps
              struggle with the discovery phase, because it requires the patience to ask the right questions and to
              truly listen to the client. It’s a mixture of research, follow-up questions and some limited selling. This is
              inherently difficult for the bottom 95% who just want to sell, sell, sell.</Text>
            <Text {...textStyle}>Any top-tier solution provider will have a well thought out and organized discovery form that their reps
              should use as a template (more to come on this document in a future article). The discovery document
              should be broken down in the following areas:</Text>
            <table>
              <tr>
                <td>
                  Area 1
                </td>
                <td>
                Company Background (history, business scope and operational areas, 5-year strategic
objectives)
                </td>
              </tr>
              <tr>
                <td>
                  Area 2
                </td>
                <td>
                Current Pain Areas (inefficiencies, risk, information for decision making, growth inhibitors)
                </td>
              </tr>
              <tr>
                <td>
                  Area 3
                </td>
                <td>
                Current Software Solutions (scope, integration, effectiveness, etc.)
                </td>
              </tr>
              <tr>
                <td>
                  Area 4
                </td>
                <td>
                Evaluation Process (decision process, time frame, key individuals, competitors, etc.)
                </td>
              </tr>
            </table>
            <Text {...textStyle}>The key to optimizing the discovery process is to make sure that 80+ percent of the time your rep is
              asking intelligent (open-probe questions), which get the client to open up and be more transparent
              about the evaluation process, pain areas, etc. The rep’s job in this phase is to tease out more and more
              valuable information through initial questions and intelligent follow-up questions.</Text>
            <Text {...textStyle}>Asking effective follow-up questions is not easy in the heat of the battle – this is where a rep’s
              industry/product knowledge are key. By developing the skill of effective follow-up questions, the
              prospect begins to trust in the rep’s knowledge, allowing the rep to gain that “trusted advisor” status.
              The result is a rich tapestry of information to build the business case on. Too often, the rep wants to
              launch into long-winded sales pitches (phone or in person), which turns the client off and the invisible
              sales rep shields go up. It’s not about showing the client how much you know or how good your product
              is, it’s about listening and gaining critical information that will be instrumental later in the sales cycle.</Text>
            <Text {...textStyle}><b>Pain Area Identification</b> – current inefficiencies and associated pain are the most information to take
              away from the discovery process. Uncovering inefficiencies will be critical to forming the business case
              through product demonstrations and any ROI (Return on Investment) analysis you put in front of the
              decision team. Inefficiencies fall into the categories of productivity (hours), added cost/profitability, risk
              and lack of information for decision making. Its critical that discovery uncovers clear examples of pain
              that your solution effectively addresses. Clients generally won’t enter a change process without the
              clear idea of how the solution will improve performance and profitability.</Text>
          </Box>
          <Box {...row} style={{marginTop:60}}>
            <Text {...title}>3. Product Demonstrations</Text>
            <Text {...textStyle}>Many sales campaigns are won or lost during this critical phase of the selling process. Product demos
              fall into 2 categories:</Text>
            <Text {...textStyle} style={{marginLeft: 20}}>
              <b>1. Overview or Inspirational Demos</b><br />
              <b>2. In-depth Due-Diligence Demos</b>
            </Text>
            <Text {...textStyle}>Both types have very clear goals and objectives, and the sales campaign hinges on absolute excellence in
              each area. Too many companies spend too much money finding qualified opportunities, only to stumble
              making the business case for their solution during this phase.</Text>
            <Text {...textStyle}><b>Overview or Inspirational Demos</b> – these demos are an effective way for clients to pre-screen solutions
              in order to short-list 2 or 3 products for the in-depth presentations. Overview demos typically last 1-2
              hours and may or may not involve decision makers.</Text>
            <Text {...textStyle}>The big mistake many vendors make is taking these presentations too lightly and not allocating the right
              resources for success. It is very common for the sales rep to provide these overview demos, which can
              be risky depending on their experience and product knowledge. A mediocre overview product demo
              can cause elimination from the finals, even though you may have the best solution for the client.
              Alternatively, a top-notch overview demo can inspire the client and put your team on a very strong
              trajectory to win the business.</Text>
            <Text {...textStyle}>Dos’ and don’ts about overview demos:</Text>
            <table className="tableStyle2">
              <tr>
                <th>Do</th>
                <th>Don't</th>
              </tr>
              <tr>
                <td>Use discovery info to prep for overview demos</td>
                <td>Take overview demos lightly – they are critical</td>
              </tr>
              <tr>
                <td>Put your best demo resources on large value
                  opportunities - critical</td>
                <td>Bore your client with long-winded one-way
                  presentations. Ask questions along the way to
                  keep the client engaged</td>
              </tr>
              <tr>
                <td>Position your solution against your competitors
                  that you know are in the deal</td>
                <td>Demo features only – you must show features
                    and benefits</td>
              </tr>
            </table>
            <Text {...textStyle}><b>In-depth Due-Diligence Demos</b> – these demos take much more time and prep work to effectively
              manage and excel at. In this step, the client brings in stakeholders who will be using the solution to get
              their “read” on how they feel it will work in daily use. Getting the enthusiastic buy-in from these groups
              is pivotal to winning the business, requiring significant research (discovery) and prep time to ensure
              these users have a great experience and give your solution the top grade.</Text>
            <Text {...textStyle}><b>Preparation is key</b> – in-depth demos can last hours or even days, so you must draw on your discovery
                data to make sure these users feel their work-life will be significantly improved with your solution. It’s
                easy to get bogged down in details, making it critical that you make a clear and effective case for a
                specific feature, then move on. If end users feel the solution is overly complex or confusing, your
                proverbial goose is cooked. Your presentation team should act like a group of experienced trial lawyers,
                making the most clear and compelling case possible, while avoiding landmines - or at least minimizing

                them. Typically, solution engineers are presenting the in-depth feature set and answering questions.
                However, the sales rep must stay engaged in order to manage the presentation and take constant reads
                on how the presentation is being received.</Text>
            <Text {...textStyle}><b>Raising the bar on your competitors through a Business Case Study</b> – it requires more preparation and
              work, but many top-tier solution providers will ask for sample data from the client in order to make the
              demo even more relevant and easy to understand. Clients react very favorably to seeing their data
              flowing through the system, making it much easier for them to understand key features of your solution.
              Showing the client’s data moving through your solution helps the client better visualize the solution,
              reducing confusion and lowering the learning curve. Case Studies, if done correctly, help increase buy-
              in and reduce resistance to change.</Text>
              <table className="tableStyle2">
                <tr>
                  <th>Do</th>
                  <th>Don't</th>
                </tr>
                <tr>
                  <td>Use discovery info to show how your solution will
clearly address pain points &amp; inefficiencies</td>
                  <td>Show features that aren’t important to the
client</td>
                </tr>
                <tr>
                  <td>Ask for feedback after each key feature is
demonstrated. Make sure the client is in
agreement as to how the feature works, as well as
its benefits</td>
                  <td>Bore your client with long-winded one-way
presentations. Ask questions along the way to
keep the client engaged</td>
                </tr>
                <tr>
                  <td>Put your best demo resources on large value
opportunities – critical. Depending on the
solution this may be a team of sales engineers</td>
                  <td>Demo features only – you must show features
and benefits</td>
                </tr>
                <tr>
                  <td>Avoid confusion and complexity when showing
how your solution will simplify work-flows and
processes. Buyers don’t appreciate complex
solutions that are hard to understand</td>
                  <td>Leave a section of the presentation until the key
questions are addressed</td>
                </tr>
              </table>

          </Box>
          <Box {...row} style={{marginTop:60}}>
            <Text {...title}>4. Making The Business Case</Text>
            <Text {...textStyle}>Many companies don’t understand this phase of the sales process, or they don’t make a compelling
              business case in order to win the business. Most sales professionals would agree that the number 1
              competitor is: “do nothing” or “status quo”. In many of these opportunities, the solution provider did
              not make a compelling business case to decision makers relative to the investment. Companies by their
              very nature have competing investment priorities. If you don’t make an effective business case for your
              solution, you will lose to a competitor, to other unrelated business priorities, or to simply inaction on
              the part of the client. This outcome is extremely prevalent in high value sales.</Text>
            <Text {...textStyle}>The key to making an effective business case is to tie the pain (inefficiencies) during the discovery
              process to quantifiable outcomes using your solution. Often, this is referred to as <b>ROI (Return on
              Investment)</b>. However, there are risks to your credibility, and ultimately whether you succeed, if the
              client doesn’t buy into your ROI methodology and assumptions. Your ROI presentation must be
              reasonable and understandable, otherwise the client simply won’t believe your business case. ROI
              business cases fall into these categories:</Text>
            <table className="tableStyle2">
              <tr>
                <th>Category</th>
                <th>Examples</th>
              </tr>
              <tr>
                <td>Productivity</td>
                <td>Efficiency, time savings, cost reduction</td>
              </tr>
              <tr>
                <td>Business Insight - Intangible</td>
                <td>Profitability, risk management</td>
              </tr>
              <tr>
                <td>Business Growth</td>
                <td>Revenue gain, diversification</td>
              </tr>
            </table>
            <Text {...textStyle}>The most commonly used ROI category is: <b>Productivity</b>. During discovery, it is critical to understand the
              client’s key business process (workflows) as well as how long and what resources are involved in
              completing the process. Once you’ve documented the processes at the client, you can then show how
              your solution is working at clients who use your solution. By showing real-world client examples, you
              gain the credibility that the solution will indeed provide the benefits touted. Business owners will rarely
              make a significant investment without some proof that their investment will return the results expected.</Text>
            <Text {...textStyle}><b>Intangible ROI</b> – don’t underestimate the power of intangible ROI (i.e. better business insights, risk
            reduction). Most decision makers have a fundamental need for better <b>control</b> and <b>visibility</b> in their
            businesses in order to reduce risk, and this type of ROI can be very compelling on an emotional level.
            Intangible ROI is best used in conjunction with productivity ROI examples to optimize the overall
            business case.</Text>
            <Text {...textStyle}><b>Client Success Stories &amp; Reference Checking</b> - most clients want to see examples (or speak with)
              companies that are similar to theirs before making a final decision. This step can dramatically help ease
              concerns, creating a clear path forward to the purchase. However, many vendors struggle by providing
              mediocre (less than fully satisfied customers) or clients who do not fit the profile of the prospective
              client. Both of these failures can be deal killers, and it is critical that the reference phase gets the
              prospect excited about your solution. A bad or mediocre reference can delay (or kill) a deal, and it is
              very difficult to recover from a misstep here. <b>Note: call or email your reference customers and give
              them good background on the opportunity and who will be calling from the client. This is
              professional and will help your customer provide the best reference possible. Never – hand out
              blanket reference lists as client temperatures and satisfaction levels can change.</b></Text>
          </Box>


          <Box {...row} style={{marginTop:60, marginBottom: 120}}>
            <Text {...title}>5. Getting Over the Finish Line</Text>
            <Text {...textStyle}>If your sales organization has done a stellar job during phases 1-4, you have certainly put your company
to be in the best possible position for success. However, there is some basic selling process “hygiene”
tasks necessary to make sure you get the decision you want in the timeframe you want. These tasks are
as follows:</Text>
            <Text {...textStyle}>              
              <b>1. Trial Closing</b><br />
              <b>2. Remaining Steps</b><br />
              <b>3. Decision Path</b><br />
              <b>4. Risk Identification</b>
              
              </Text>
            <Text {...textStyle}><b>Trial Closing</b> – this is a commonly misunderstood skill in the sales process. Many reps confuse Trial
              Closing with asking for the order (Closing) – a very overrated step of the process. Trial closing is
              essentially checking in with the client after each key event in the sales process (overview demo, in-depth
              demo, ROI, references, etc.) to make sure that the solution is the leading contender at the prospect.
              We trial-close by asking a series of open-probe questions in order to ensure our solution is the leader
              and that any concerns are uncovered. If the client hesitates or equivocates, or worst case says our
              solution is number 2 or 3 in the eyes of prospect, we know we have significant work to do. If this
              happens, it is absolutely critical to document any concerns and determine a game plan to address in the
              best way possible. The earlier you hear about concerns, the better your chances are of addressing.
              Hearing about concerns later in the sales cycle must be avoided, if at all possible, because you may not
              have time to properly address.</Text>
            <Text {...textStyle}>If the client readily agrees that our solution is number 1, we have greater confidence in forecasting the
              business for upper management. However, your solution may be considered the best of all the options
              reviewed, and the client still doesn’t feel you’ve made a compelling business case. If this happens, you
              must go back and shore up the business case, and trial-close again to see if the client is comfortable. A
              sales rep should rarely get a surprise relative to the client’s final decision. In fact, the final decision
              should be somewhat anticlimactic. Effective trial closing is one of the most important skills for top 5
              percent sales reps, and they religiously trial-close to ensure they understand where they are at relative
              to their pipeline of business.</Text>
            <Text {...textStyle}><b>Remaining Steps</b> – most reps are inherently optimistic and commonly underestimate the remaining
              steps to finalize a sale. In order to document remaining steps, your main contact has to be transparent
              about what has to be done to sign the order. The larger the client, typically the more steps there are.
              Documenting remaining steps, and getting the client to agree on the timeframe to complete is
              absolutely critical to accurately forecasting the amount and timeframe associated with the sale. Reps
              are judged by upper management on the accuracy of their forecasting, so this skill is critical.</Text>
            <Text {...textStyle}><b>Decision Path</b> – too many reps get caught by not understanding the decision path. This is the exact
              series of approvals and people who will have to sign-off on the order. In larger companies, there may be
              a board approval step, which can seriously delay the decision timeframe. There is also risk if additional
              approvers are identified at the end of the sales cycle. Does the rep understand who these people are,
              and what their attitudes are relative to the investment in the solution? Deals can definitely get derailed
              by a decision maker who is inserted later in the process, and who hasn’t participated in product
              demonstrations, ROI presentations, etc. The goal is to gain a clear understanding of the path and to
              eliminate surprises.</Text>
            <Text {...textStyle}><b>Risk Identification</b> – even if a sales process is proceeding as planned, there can be risks that can derail
              the decision. Top tier sales reps have candid conversations with their clients to try to uncover hidden
              risks so that they can address if at all possible. There are 49 ways to lose or (delay) a purchase and only
              one critical path to the successful culmination of an order. Its critical to stay vigilant to risk areas as
              they will inevitably happen.</Text>
            <Text {...title}>Summary</Text>
            <Text {...textStyle}>Managing a high-tech sales process involves strong interpersonal and relationship building skills.
              However, applying in-depth research about the client’s needs and pain areas in order to create the most
              compelling presentations and business case documentation, are even more important to ensure
              success. Top 5 percent sales reps are truly the quarterbacks of their opportunities, and their ability to
              coordinate and manage the myriad of steps and details and marshal the necessary resources is the key
              to higher win rates and success. It takes an exceptional, organized and motivated individual to win in
              this zero-sum game.</Text>
          </Box>

        </AgencyWrapper>
      </Fragment>
    </ThemeProvider>
  );
};


Saas.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: 'auto',
    mr: 'auto',
    alignItems: 'center',
    maxWidth: '90%',
    width: 900
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '70%', '60%', '50%'],
  },
  title: {
    fontSize: ['26px', '26px', '32px', '40px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.31',
  },
  textStyle: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '2.1',
    mb: '30px',
  },
};


export default Saas;
